module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Boilerplate","short_name":"Boilerplate","start_url":"/","background_color":"#0F2127","theme_color":"#0F2127","display":"standalone","orientation":"portrait-primary","lang":"fr","icon":"static/images/favicon/favicon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"4a397cb98f93b556a2af475de71efaea"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KRVMX3K","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/buddy/coty-gucci-flora21-frontend/src/intl","languages":["en","fr","de","ko","ar","br","zh-cn","es","id","it","jp","pt","ru","th","zh-hk","vn"],"defaultLanguage":"en","redirect":true,"redirectDefaultLanguageToRoot":false,"ignoredPaths":[]},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    }]
